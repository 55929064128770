<template>
	<SelectWithChips
		v-model="filters.themes"
		:disabled="themes.length === 0"
		:label="$t('client_workspace.task.themes')"
		:items="themes"
		:item-value="'title'"
		:item-text="'title'"
		:item-short-text="itemShortText"
		return-object
		list-with-chips
		:minimized="isMinimized"
		multiple
		:prepend-icon="$vuetify.breakpoint.mdAndDown ? null : 'group_work'"
		:max-selected-items-showed="minimized ? 0 : 1"
	/>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'TasksManagerFilterTheme',
	components: {
		SelectWithChips: () => ({
			component: import('@/components/Commons/SelectWithChips')
		})
	},
	props: {
		minimized: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	computed: {
		...mapState({
			themes: state => state.tasks.themes,
			filters: state => state.tasks.filters
		}),
		isMinimized: function () {
			return this.minimized || this.$vuetify.breakpoint.lgAndDown
		},
		itemShortText: function () {
			return this.isMinimized ? 'abbreviation' : null
		}
	},
	watch: {
		themes: {
			handler: function () {
				this.filters.themes.clear()
				this.filters.themes = [...this.themes]
			}
		}
	},
	created: function () {
		this.themes.forEach(theme => {
			theme.abbreviation = theme.title
				.split(' ')
				.map(part => part[0].toUpperCase())
				.join('')
		})
	}
}
</script>
